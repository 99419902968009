import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import GridItem from '../components/GridItem';
import { fixPlaceholder, getAnimations } from '../utils/functions';

const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 !important;
  height: 100%;
  position: right;
  & > div {
    opacity: 100;
    ${getAnimations()}
  }
  padding-top: 3.5rem;
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    padding-top: 0rem;
  }
`;

const CommercialPage = ({
  data: {
    projects: { edges },
    placeHolder,
  },
}) => (
  <Layout isIndex={false}>
    <Helmet>
      <title>Commercials</title>
    </Helmet>
    <RightWrapper columns={2}>
      {edges.map(c => (
        <GridItem
          uid={c.node.uid}
          key={c.node.uid}
          image={fixPlaceholder(c.node.data, placeHolder)}
          alt={c.node.data.title.text}
          title={c.node.data.title.text}
          author={c.node.data.editor}
        />
      ))}
    </RightWrapper>
  </Layout>
);

export default CommercialPage;

CommercialPage.propTypes = {
  data: PropTypes.shape({
    projects: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    placeHolder: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query CommercialQuery {
    projects: allPrismicProject(
      sort: { fields: [data___taxonomy], order: [ASC] }
      filter: { data: { project_type: { eq: "Commercial" } } }
    ) {
      edges {
        node {
          uid
          data {
            taxonomy
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 506, quality: 90, cropFocus: ENTROPY) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title {
              text
            }
            editor
          }
        }
      }
    }
    placeHolder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, maxHeight: 506, quality: 90, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
